<template>
  <v-container fluid>
    <v-widget title="Transportadores">
      <template v-slot:toolbar>
        <v-container fluid>
          <v-row dense>
            <v-col cols="10" sm="5">
              <v-text-field filled flat single-line hide-details dense clearable solo v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" prepend-inner-icon="search" background-color="grey lighten-3" label="Busca" />
            </v-col>

            <v-col cols="2" sm="7" class="text-right">
              <v-btn v-if="!isSm" depressed color="primary" @click="novo"> NOVO </v-btn>
              <v-btn v-else depressed fab small color="primary" @click="novo">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:widget-content>
        <v-data-table :options.sync="options" :server-items-length="transportadores.length" :headers="headers" :items="transportadores" :loading="carregando" mobile-breakpoint="0">
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.razaoSocialNome }}</td>
              <td class="text-no-wrap">{{ props.item.cnpjCpf }}</td>
              <td>{{ props.item.rgIe }}</td>
              <td>{{ props.item.cidade }}</td>
              <td>{{ props.item.estado }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="props.item._id + 20" @click="editar(props.item)" data-cy="editar">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="props.item._id + 40" @click="remover(props.item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-widget>

    <dialog-confirmacao ref="dialogConfirmacao"></dialog-confirmacao>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';
import DialogConfirmacao from '../../components/dialogs/DialogConfirmacao.vue';

export default {
  components: {
    DialogConfirmacao,
  },
  data() {
    return {
      headers: [
        { text: 'Razão Social / Nome', value: 'razaoSocialNome' },
        { text: 'CNPJ / CPF', value: 'cnpjCpf' },
        { text: 'Inscrição Estadual/ RG', value: 'inscricaoRg' },
        { text: 'Cidade', value: 'cidade' },
        { text: 'Estado', value: 'estado' },
        { text: '', value: 'name', align: 'center', width: '32px', sortable: false },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('transportador', {
      transportadores: 'transportadores',
      carregando: 'carregando',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    computedOptions() {
      return { itemsPerPage: this.options.itemsPerPage, page: this.options.page, busca: (this.busca && this.busca.trim()) || null };
    },
  },

  methods: {
    listar() {
      this.$store.dispatch('transportador/listar', this.computedOptions);
    },

    editar(transportador) {
      this.$store.commit('transportador/setState', { transportador });
      this.$router.push({ name: 'formularioTransportadorEditar' });
    },

    novo() {
      this.$store.dispatch('transportador/novo');
      this.$router.push({ name: 'formularioTransportadorNovo' });
    },

    limparBusca() {
      this.busca = null;
      this.listar();
    },

    filtrar(event) {
      this.listar();
      this.$nextTick(() => event.target.select());
    },

    remover(transportador) {
      this.$refs.dialogConfirmacao.open('Remover', 'Tem certeza que deseja remover?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('transportador/remover', transportador._id, this.computedOptions);
        }
      });
    },
  },
};
</script>
